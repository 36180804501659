import React from 'react'
import experience from '../../../styles/icons/experience.png'
import experience_mob from '../../../styles/icons/experience_mob.png'
import './style.css'
import { REGION } from '../../../constants'

export function ExperienceBlock({ size, whyWeRef }) {
  return (
    <>
      {size === 'BIG' ? (
        <div className='ExperienceBlock'>
          <div className='ExperienceBodyBlock'>
            <div className='headerExperienceBlock' ref={whyWeRef}>
              {REGION === 'msc' ? 'Мы' : 'ControlGPS '}{' '}
              <span className='emphasized'>более 4-х лет</span>{' '}
              {REGION === 'msc' ? 'работаем' : 'работает'} на рынке логистики и такси
            </div>
            <div className='bodyExperienceBlock'>
              <img src={experience} alt='' className='experienceIconExperienceBlock' />
              <div className='experienceListExperienceBlock'>
                <div className='experienceItemExperienceBlock'>
                  <div className='experienceItemHeaderExperienceBlock emphasized'>Опыт</div>
                  <div className='experienceItemBodyExperienceBlock'>
                    За более чем 4 года на рынке, мы изучили огромное количество внутренних проблем
                    конкурентов. Имеем большой опыт оптимизации и разработки онлайн систем.
                  </div>
                </div>
                <div className='experienceItemExperienceBlock'>
                  <div className='experienceItemHeaderExperienceBlock emphasized'>Качество</div>
                  <div className='experienceItemBodyExperienceBlock'>
                    Мы сотрдничаем с лучшими поставщиками оборудования, это позволяет нам
                    предоставить лучший продукт среди конкурентов.
                  </div>
                </div>
                <div className='experienceItemExperienceBlock'>
                  <div className='experienceItemHeaderExperienceBlock emphasized'>
                    Функциональность
                  </div>
                  <div className='experienceItemBodyExperienceBlock'>
                    Мы провели глубокую экспертизу рынка, и применили лучшие практики разработки.
                    Это помогло нам разработать решение, которое переведёт рынок трекинговых систем
                    на новый уровень.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='ExperienceBlock_mob'>
          <div className='ExperienceBodyBlock_mob'>
            <div className='headerExperienceBlock_mob' ref={whyWeRef}>
              {REGION === 'msc' ? 'Мы' : 'ControlGPS '}{' '}
              <span className='emphasized'>более 4-х лет</span>{' '}
              {REGION === 'msc' ? 'работаем' : 'работает'} на рынке логистики и такси на рынке
              логистики и такси
            </div>
            <div className='bodyExperienceBlock_mob'>
              <div className='experienceListExperienceBlock_mob'>
                <div className='experienceItemExperienceBlock_mob'>
                  <div className='experienceItemHeaderExperienceBlock_mob emphasized'>Опыт</div>
                  <div className='experienceItemBodyExperienceBlock_mob'>
                    За более чем 4 года на рынке, мы изучили огромное количество внутренних проблем
                    конкурентов. Имеем большой опыт оптимизации и разработки онлайн систем.
                  </div>
                </div>
                <div className='experienceItemExperienceBlock_mob'>
                  <div className='experienceItemHeaderExperienceBlock_mob emphasized'>Качество</div>
                  <div className='experienceItemBodyExperienceBlock_mob'>
                    Мы сотрдничаем с лучшими поставщиками оборудования, это позволяет нам
                    предоставить лучший продукт среди конкурентов.
                  </div>
                </div>
                <div className='experienceItemExperienceBlock_mob'>
                  <div className='experienceItemHeaderExperienceBlock_mob emphasized'>
                    Функциональность
                  </div>
                  <div className='experienceItemBodyExperienceBlock_mob'>
                    Мы провели глубокую экспертизу рынка, и применили лучшие практики разработки.
                    Это помогло нам разработать решение, которое переведёт рынок трекинговых систем
                    на новый уровень.
                  </div>
                </div>
              </div>
              <div className='experienceIconParentExperienceBlock_mob'>
                <img src={experience_mob} alt='' className='experienceIconExperienceBlock_mob' />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
