import React from 'react'
import logo_footer from '../../../styles/icons/logo_footer.svg'
import logo_footer_mob from '../../../styles/icons/logo_footer_mob.svg'
import logo_spb from '../../../styles/icons/logo_spb.png'
import phone_footer from '../../../styles/icons/phone_footer.svg'
import phone_footer_mob from '../../../styles/icons/phone_footer_mob.svg'

import './style.css'
import { PHONE_NUMBER, PHONE_NUMBER_VALUE, REGION } from '../../../constants'

export function BottomBarBlock({ size }) {
  const logo = REGION === 'msc' ? logo_footer : logo_spb
  const logo_mob = REGION === 'msc' ? logo_footer_mob : logo_spb

  return (
    <>
      {size === 'BIG' ? (
        <div className='BottomBarBlock'>
          <div className='bodyBottomBarBlock'>
            <div>
              <div className='otdelBottomBarBlock'>Отдел продаж:</div>
              <div className='phoneBottomBarBlock'>
                <img src={phone_footer} alt='' />
                <a href={PHONE_NUMBER_VALUE} className='phoneABottomBarBlock'>
                  {PHONE_NUMBER}
                </a>
              </div>
              <div className='periodBottomBarBlock'>Часы работы: 9:00 - 18:00 МСК</div>
            </div>
            <div style={{ marginRight: '32px' }}>
              <div className='logoBottomBarBlock'>
                <img
                  src={logo}
                  alt=''
                  className={REGION === 'msc' ? 'imgBottomBarBlockMsc' : 'imgBottomBarBlockSpb'}
                />
              </div>
              <a
                href={
                  REGION === 'msc'
                    ? 'https://crm.controlgps.org/privacy_policy'
                    : 'https://crm.controlgps.org/privacy_policy_spb'
                }
                className='politBottomBarBlock'
                target='_blank'
              >
                Политика конфиденциальности
              </a>
              {REGION === 'msc' ? (
                <>
                  <div className='ofBottomBarBlock'>ООО "Контрол Девелопмент"</div>
                  <div className='ofBottomBarBlock'>ОГРН 1237700601380 ИНН 7751266590</div>
                </>
              ) : (
                <>
                  <div className='ofBottomBarBlock'>ООО "ИМАКС Драйв"</div>
                  <div className='ofBottomBarBlock'>ОГРН 1247800097710 ИНН 7810965747 </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='BottomBarBlock_mob'>
          <div className='bodyBottomBarBlock_mob'>
            <div className='logoBottomBarBlock_mob'>
              <img
                src={logo_mob}
                alt=''
                className={
                  REGION === 'msc' ? 'imgBottomBarBlockMsc_mob' : 'imgBottomBarBlockSpb_mob'
                }
              />
            </div>
            <div className='otdelBottomBarBlock_mob'>Отдел продаж:</div>
            <div className='phoneBottomBarBlock_mob'>
              <img src={phone_footer_mob} alt='' height='20px' width='20px' />
              <a href={PHONE_NUMBER_VALUE} className='phoneABottomBarBlock'>
                {PHONE_NUMBER}
              </a>
            </div>
            <div className='periodBottomBarBlock_mob'>Часы работы: 9:00 - 18:00 МСК</div>
            <a
              href={
                REGION === 'msc'
                  ? 'https://crm.controlgps.org/privacy_policy'
                  : 'https://crm.controlgps.org/privacy_policy_spb'
              }
              className='politBottomBarBlock_mob'
              target='_blank'
            >
              Политика конфиденциальности
            </a>
            {REGION === 'msc' ? (
              <>
                <div className='ofBottomBarBlock_mob'>ООО "Контрол Девелопмент"</div>
                <div className='ofBottomBarBlock_mob'>ОГРН 1237700601380 ИНН 7751266590</div>
              </>
            ) : (
              <>
                <div className='ofBottomBarBlock_mob'>ООО "ИМАКС Драйв"</div>
                <div className='ofBottomBarBlock_mob'>ОГРН 1247800097710 ИНН 7810965747 </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}
