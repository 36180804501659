import React, { useState } from 'react'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Transition } from 'react-transition-group'

import logo_msc from '../../../styles/icons/logo.svg'
import logo_spb from '../../../styles/icons/logo_spb.png'
import logo_msc_mob from '../../../styles/icons/logo_mob.svg'
import phone from '../../../styles/icons/phone.svg'
import phone_mob from '../../../styles/icons/phone_mob.svg'
import user from '../../../styles/icons/user.svg'
import menu_mob from '../../../styles/icons/menu_mob.svg'
import './style.css'
import { PHONE_NUMBER, PHONE_NUMBER_VALUE, REGION } from '../../../constants'

export function TopBarBlock({ size, whyWe, advantages, benefits }) {
  const [openMenu, setOpenMenu] = useState(false)

  const logo = REGION === 'msc' ? logo_msc : logo_spb
  const logo_mob = REGION === 'msc' ? logo_msc_mob : logo_spb

  console.log('REGION: ', REGION)
  console.log('process.env.REGION: ', process.env.REGION)

  const duration = 100

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    display: 'none',
  }

  const transitionStyles = {
    entering: { opacity: 1, display: 'block' },
    entered: { opacity: 1, display: 'block' },
    exiting: { opacity: 0, display: 'block' },
    exited: { opacity: 0, display: 'none' },
  }

  const login = () => {
    document.location.href = 'http://crm.controlgps.org/'
  }

  return (
    <>
      {size === 'BIG' ? (
        <div className='TopBarBlock'>
          <div className='navbar'>
            <img
              src={logo}
              alt=''
              className={REGION === 'msc' ? 'logoNavbarMsc' : 'logoNavbarSpb'}
            />
            <div className='textNavbar' onClick={whyWe}>
              Почему мы
            </div>
            <div className='textNavbar' onClick={advantages}>
              Преимущества
            </div>
            <div className='textNavbar' onClick={benefits}>
              Выгода
            </div>
            <div className='phoneAndLogin'>
              <div className='phoneNavbar'>
                <img src={phone} alt='' className='phoneIconNavbar' />
                <a href={PHONE_NUMBER_VALUE} className='telNavbar'>
                  {PHONE_NUMBER}
                </a>
              </div>
              <div className='loginNavbar'>
                <img src={user} alt='' className='userIconNavbar' />
                <div className='loginTextNavbar' onClick={login}>
                  Войти в систему
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='TopBarBlock'>
          <div className='navbar_mob'>
            <img
              src={logo_mob}
              alt=''
              className={REGION === 'msc' ? 'logoNavbarMsc_mob' : 'logoNavbarSpb_mob'}
            />
            <div className='phoneNavbar_mob'>
              <img src={phone_mob} alt='' className='phoneIconNavbar_mob' />
              <a href={PHONE_NUMBER_VALUE} className='telNavbar_mob'>
                {PHONE_NUMBER}
              </a>
            </div>
            <div className='menuNavbar'>
              <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                <div className='iconAndBodyMenuNavbar'>
                  <img
                    src={menu_mob}
                    className='iconMenuNavbar'
                    onClick={() => setOpenMenu(!openMenu)}
                    alt=''
                  />
                  <Transition in={openMenu} timeout={duration}>
                    {(state) => (
                      <div
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[state],
                        }}
                        className='menuBodyNavbar'
                      >
                        <div className='textMenuBodyNavbar' onClick={whyWe}>
                          Почему мы
                        </div>
                        <div className='textMenuBodyNavbar' onClick={advantages}>
                          Преимущества
                        </div>
                        <div className='textMenuBodyNavbar' onClick={benefits}>
                          Выгода
                        </div>
                        <div className='textMenuBodyNavbar' onClick={login}>
                          Войти в систему
                        </div>
                      </div>
                    )}
                  </Transition>
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
